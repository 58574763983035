.pagination {
  margin: 1rem;
  display: flex;
  list-style: none;
  outline: none;
}
.pagination > .active > a{
  background-color: black;
  border-color: rgb(0, 0, 0);
  color: #fff;
}
.pagination > li > a{
  border: 1px solid rgb(0, 0, 0);
  padding: 0.5em 0.5em;
  outline: none;
  cursor: pointer;
}
.pagination > .active > a, .pagination > .active > span, .pagination > .active > a:hover, .pagination > .active > span:hover, .pagination > .active > a:focus, .pagination > .active > span:focus{
  background-color: #8B3995;
  border-color: rgb(0, 0, 0);
  outline: none ;
}
.pagination > li > a, .pagination > li > span{
  color: rgb(0, 0, 0)
}
.pagination > li:first-child > a, .pagination > li:first-child > span, .pagination > li:last-child > a, .pagination > li:last-child > span{
  border-radius: unset
}

@media (min-width:1025px) { /* big landscape tablets, laptops, and desktops */
  .pagination {
    margin: 2rem;
    display: flex;
    list-style: none;
    outline: none;
  }
  .pagination > .active > a{
    background-color: black;
    border-color: rgb(0, 0, 0);
    color: #fff;
  }
  .pagination > li > a{
    border: 1px solid rgb(0, 0, 0);
    padding: 5px 10px;
    outline: none;
    cursor: pointer;
  }
  .pagination > .active > a, .pagination > .active > span, .pagination > .active > a:hover, .pagination > .active > span:hover, .pagination > .active > a:focus, .pagination > .active > span:focus{
    background-color: #8B3995;
    border-color: rgb(0, 0, 0);
    outline: none ;
  }
  .pagination > li > a, .pagination > li > span{
    color: rgb(0, 0, 0)
  }
  .pagination > li:first-child > a, .pagination > li:first-child > span, .pagination > li:last-child > a, .pagination > li:last-child > span{
    border-radius: unset
  }
}