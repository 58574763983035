.all-login-cont{
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-direction: column;
}

.login-logo:hover{
    transform: none;
}

.login-logo img{
    width: 100%;
}

.login-cont{
    position: relative;
    width: 100%;
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.input-bar{
    height: 2.5rem;
    width: 80%;
}

.login-btn{
    margin-top: 10rem;
    padding: 0 2rem;
    height: 10vh;
    width: 60%;
    font-size: 1.5em;
    border-style: none;
    color: rgb(255, 255, 255);
    background-color: #8c5494;
    box-shadow: 5px 5px 10px #646464;
}

@media (min-width:1025px) { /* big landscape tablets, laptops, and desktops */    
    .input-bar{
        height: 2.5rem;
        width: 40rem;
    }
    
    .login-btn{
        height: 3rem;
        width: 20rem;
        padding: 0;
    }
}