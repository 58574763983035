* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: sans-serif;
}

.app{
  background-color: #3F403F;
  display:flex;
  justify-content: center;
}

.full-page-section{  
  min-height: 100vh;
  width: 100%;
  background-color: #f0f0f0;
}

@media (min-width:1025px)  { /* big landscape tablets, laptops, and desktops */
  .full-page-section{
    width: 90%;
  }
}