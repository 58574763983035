.admin-results-cont{
    display: flex;
    justify-content: center;
    align-items: center;
}

.admin-books-results-cont{
    width: 80%;
}

.edit-btn-cont{
    display: flex;
    justify-content: center;
}

.editing-btns-cont{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.edit-btn{
    margin: 1rem;
    height: 2.5rem;
    width: 5rem;
    font-size: 1.5em;
    background-color: #ffffff;
    border: 3px solid #8B3995;
    color: #8B3995;
    box-shadow: 5px 5px 10px #646464;
}

.textarea{
    margin: 1rem;
    width: 80%;
    height: 15rem;
}


@media (min-width:641px)  { /* portrait tablets, portrait iPad, landscape e-readers, landscape 800x480 or 854x480 phones */ }

@media (min-width:1025px) { /* big landscape tablets, laptops, and desktops */
    .edit-btn:hover{
        transform: translate(0.3rem, -0.3rem);
        box-shadow: 5px 5px 10px #202020;
        border-radius: 0px;
    }

    .editing-btns-cont{
        flex-direction: row;
    }

    .books-width{
        width: 50%;
    }

    .textarea{
        width: initial;
        height: initial;
    }

    .save-btn{
        margin: 1rem;
        height: 3rem;
        width: 50%;
        border-radius: 10px;
        font-size: 1.5em;
        border-style: none;
        color: rgb(255, 255, 255);
        background-color: #8c5494;
        box-shadow: 5px 5px 10px #646464;
    }

    .save-btn:hover{
        background-color: #a159aa;
        transform: translate(0.3rem, -0.3rem);
    }

    .cancel-btn{
        margin: 1rem;
        height: 3rem;
        width: 50%;
        border-radius: 10px;
        font-size: 1.5em;
        background-color: #ffffff;
        border: 3px solid #444444;
        color: #444444;
        box-shadow: 5px 5px 10px #646464;
    }

    .cancel-btn:hover{
        transform: translate(0.3rem, -0.3rem);
        box-shadow: 5px 5px 10px #202020;
    }
}