.count-results-cont{
  margin: 1.5rem;
}

.p-results-container{
  margin-bottom: 1rem;
  padding: 1rem;
  background-color: rgb(179, 212, 255);
}

.b-results-container{
  margin-bottom: 1rem;
  padding: 1rem;
  background-color: rgb(179, 212, 255);
}

.results-name-cont{
  font-size: 22px;
  margin: 0 0 0 1rem;
}

.results-lifespan{
  margin-left: 1rem;
  font-size: 18px;
  color: darkslategrey;
}

.results-text{
  margin: 0 0 0.5rem 1rem;
  font-size: 16px;
}

@media (min-width:1025px) { /* big landscape tablets, laptops, and desktops */
  .has-searched{
    margin: 20px 0 20px 0;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: auto;
    grid-template-areas: 
      "bar bar bar btn";
  }
  
  .has-searched-bar{
    grid-area: bar;
  }
  
  .has-searched-bar input{
    width: 90%;
  }
  
  .has-searched-btn{
    grid-area: btn;
  }
  
  .all-results-cont{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
  }

  .p-results-container{
    width: 45%;
  }
}