.main-container{
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 24vh;
}

.main-header-cont{
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.sup-logo{
  padding: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sup-logo img{  
  width: 80%;
}

.sup-logo:hover{
  transform: skew(2deg);
}

.contact-info-cont{
  display: none;
}

.contact-info{
  text-align: left;
}

.contact-info p{
  font-size: 1.125em;
}

hr{
  display: none;
}

.home-heads-cont{
  width: 100%;
  min-height: 20vh;
  /* background-color: #B7D6EF; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.home-h1{
  font-size: 1.875em;
  margin: 1rem;
  text-align: center;
}

.home-h3{
  font-size: 1.56em;
  margin: 1rem;
  text-align: center;
}

.mobile-contact-info-cont{
  text-align: center;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.home-search-btn-cont{
  width: 100%;
  min-height: 60vh;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  background-image: url("../../SUP\ Bldg\ from\ North\ 2015.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.home-search-btn-cont a{
  text-decoration: none;
}

.home-search-btn{
  display: none;
}

.sup-mission-cont{
  width: 100%;
  min-height: 25vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* background-color: #B7D6EF; */
}

.home-h4{
  font-size: 1.56em;
  text-align: center;
  margin-top: 2rem;
}

.sup-mission-cont p{
  margin: 2rem 0 2rem 0;
  width: 70%;
  font-size: 1.25em;
  text-align: center;
  font-weight: 100;
  color: rgba(0, 0, 0, 0.75);
}

.mobile-home-search-btn-cont{
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
}

.mobile-btn{
  padding: 0 2rem;
  height: 12vh;
  width: 100%;
  font-size: 1.5em;
  border-style: none;
  border: 6px solid #8B3995;
}

.pioneer-btn{
  border-top: none;
}

@media (min-width:641px)  { /* portrait tablets, portrait iPad, landscape e-readers, landscape 800x480 or 854x480 phones */  
  .home-h1{
    font-size: 3.125em;
  }
  
  .home-h3{
    font-size: 2.5em;
  }

  .home-h4{
    font-size: 1.875em;
  }

  .mobile-home-search-btn{
    font-size: 2em;
  }
}
@media (min-width:961px)  { /* tablet, landscape iPad, lo-res laptops ands desktops */ 

}
@media (min-width:1025px) { /* big landscape tablets, laptops, and desktops */
  .main-container{
    margin: 0;
  }

  .sup-logo img{
    width: 100%;
  }

  .home-heads-cont{
    margin-bottom: 2rem;
  }

  .contact-info-cont{
    text-align: center;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .mobile-contact-info-cont{
    display: none;
  }

  .home-search-btn-cont{
    min-height: 70vh;
  }

  .home-search-btn{
    display: initial;
    margin: 5rem;
    padding: 2rem;
    font-size: 1.5em;
    box-shadow: 5px 5px 10px #444444;
    border-radius: 10px;
    position: relative;
    top: -8rem;
    border-style: none;
  }
  
  .home-search-btn:hover{    
    color: white;
    background-color: #a159aa;
    transform: scale(1.08);
    box-shadow: 5px 5px 10px #444444;
    border-radius: 0px;
  }

  .mobile-home-search-btn-cont{
    display: none;
  }

  hr{
    display: block;
    width: 60%;
    border: 0.5px solid black;
  }
}