.search-container{
    margin-bottom: 15vh;
}

.search-header-bg-img-cont{
    width: 100%;
    min-height: 15vh;
    background-image: url("../../SUP\ Bldg\ from\ North\ 2015.jpg");
    background-size: cover;
    background-repeat: no-repeat;
}

.search-header-container{
    display: flex;
    justify-content: space-evenly;
}

.search-h1{
    font-size: 1.5em;
    display: flex;
    align-items: center;
    text-align: center;
}

.search-bl-container{
    display: none;
}
  
.search-cInfo{
    text-align: center;
    margin: 2rem;
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.search-form {
    margin: 1rem 0 1rem 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
}

.search-bar{
    width: 85%;
    height: 2rem;
    font-size: 1.25em;
}

.search-form label{
    margin: 1rem;
    pointer-events: none;
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
}

.search-context{
    font-weight: bold;
    text-align: center;
    /* color: #646464; */
}

.search-btn-container{
    display: none;
}

.page-info {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
}

.page-count{
    display: flex;
    align-items: center;
}

.top-paginate {
    margin: 1rem 1rem 1rem 0;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.mobile-search-btn-cont{
    width: 100%;
    position: fixed;
    bottom: 0;
    left: 0;
    display: flex;
    flex-direction: column;
}
  
.mobile-search-btn{
    padding: 0 2rem;
    height: 12vh;
    width: 100%;
    font-size: 1.5em;
    color: white;
    background-color: #8B3995;
    border-style: none;
}

.bottom-paginate{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.back-to-top{
    margin-top: 1rem;
    text-decoration: none;
    font-size: 20px;
}

@media (min-width:641px)  { /* portrait tablets, portrait iPad, landscape e-readers, landscape 800x480 or 854x480 phones */ 
    .search-header-bg-img-cont{
        width: 100%;
        background-image: url("../../SUP\ Bldg\ from\ North\ 2015.jpg");
        background-size: cover;
        background-repeat: no-repeat;
        background-position: 0 20%;
    }

    .search-h1{
        font-size: 1.875em;
    }

    .mobile-search-btn{
        font-size: 2em;
    }
}

@media (min-width:1025px) { /* big landscape tablets, laptops, and desktops */
    .search-container{
        margin-bottom: 0;
    }
    
    .search-bl-container{
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .search-back-link{
        color: black;
        text-decoration: none;
        font-weight: bold;
        font-size: 1.5em;
    }
    
    .search-back-link:hover{
        transform: skew(15deg);
        text-decoration: underline;
    }
    
    .search-contact-info-cont{
        margin: 2rem;
    }
    
    .search-form-container{
        margin: 2rem 0 2rem 0;
    }
    
    .search-btn-container{
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    .search-btn{
        margin: 1rem;
        height: 3rem;
        width: 10rem;
        border-radius: 10px;
        font-size: 1.5em;
        border-style: none;
        color: rgb(255, 255, 255);
        background-color: #8c5494;
        box-shadow: 5px 5px 10px #646464;
    }
    
    .search-btn:hover{
        background-color: #a159aa;
        transform: translate(0.3rem, -0.3rem);
    }

    .mobile-search-btn-cont{
        display: none;
    }

    .page-count{
        width: 30%;
    }

    .page-info{
        flex-direction: initial;
    }

    .top-paginate{
        margin: initial;
        flex-direction: row;
        width: 70%;
    }

    .bottom-paginate{
        justify-content: space-evenly;
        flex-direction: row;
        margin: 2rem;
    }

    .back-to-top:hover{
        transform: skew(15deg);
        text-decoration: underline;
    }
}
