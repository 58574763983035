.adminAdd-buttons-cont{
    margin: 2rem;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;
}

.adminAdd-logo:hover{
    transform: skew(2deg);
}

.new-item{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.new-item-head{
    text-align: center;
}

.admin-add-btn{
    margin: 2rem;
}

.input-box{
    padding: 1rem;
    margin: 1rem;
    width: 95%;
}

.save-btn{
    margin: 1rem;
    height: 3rem;
    width: 50%;
    border-radius: 10px;
    font-size: 1.5em;
    border-style: none;
    color: rgb(255, 255, 255);
    background-color: #8c5494;
    box-shadow: 5px 5px 10px #646464;
}

.cancel-btn{
    margin: 1rem;
    height: 3rem;
    width: 50%;
    border-radius: 10px;
    font-size: 1.5em;
    background-color: #ffffff;
    border: 3px solid #444444;
    color: #444444;
    box-shadow: 5px 5px 10px #646464;
}


@media (min-width:641px)  { /* portrait tablets, portrait iPad, landscape e-readers, landscape 800x480 or 854x480 phones */ }

@media (min-width:1025px) { /* big landscape tablets, laptops, and desktops */
    
    .adminAdd-buttons-cont{
        margin: 2rem;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        flex-direction: row;
    }
}