.admin-head-cont{
    min-height: 15vh;
}

.admin-logo{
    display: none;
}

.admin-logo:hover{
    transform: none;
}

.logout-cont{
    display: flex;
    justify-content: center;
    align-items: center;
}

.logout-btn{
    color: black;
    border: 0;
    background: inherit;
    font-weight: bold;
    font-size: 1.5em;
}

.logout-btn:hover{
    transform: skew(15deg);
    text-decoration: underline;
}

.row1-content-cont{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.filter-cont{
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 1rem;
}

.filter-cont p{
    font-weight: bold;
}

.chkbox-cont{
    margin: 0.5rem;
    display: flex;
    align-items: center;
}

.chkbox-option{
    margin: 0.5rem;
    height: 2rem;
    width: 2rem;
}

.admin-btn{
    height: 4rem;
    width: 10rem;
    font-size: 1.5em;
    background-color: #ffffff;
    border: 3px solid #8B3995;
    color: #8B3995;
    box-shadow: 5px 5px 10px #646464;
}

.admin-btn:hover{
    transform: translate(0.3rem, -0.3rem);
    box-shadow: 5px 5px 10px #202020;
    border-radius: 0px;
  }

.add-entry-btn-cont{
    display: none;
}

.mobile-btn-cont{
    width: 100%;
    position: fixed;
    bottom: 0;
    left: 0;
    display: flex;
    z-index: 1;
}

.mobile-btn-cont a{
    width: 50%;
}

.mobile-entry-btn{
    padding: 0 2rem;
    height: 12vh;
    width: 100%;
    font-size: 1.5em;
    border-style: none;
    border: 6px solid #8B3995;
}

.mobile-adminSearch-btn{
    padding: 0 2rem;
    height: 12vh;
    width: 50%;
    font-size: 1.5em;
    color: white;
    background-color: #8B3995;
    border-style: none;
}

.mapped-results{
    margin-top: 2rem;
    padding-bottom: 1.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.no-results-cont{
    margin: 4rem;
    font-size: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
}

@media (min-width:641px)  { /* portrait tablets, portrait iPad, landscape e-readers, landscape 800x480 or 854x480 phones */ 
    .admin-logo{
        display: flex;
    }
}

@media (min-width:1025px) { /* big landscape tablets, laptops, and desktops */
    .row1-content-cont{
        margin-top: 1rem;
        display: flex;
        justify-content: space-evenly;
        flex-wrap: wrap;
    }
    
    .add-entry-btn-cont{
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }

    .mobile-btn-cont{
        display: none;
    }
}